/* /src/variables */

:root {
    /* font */

    /* colors */
    --color-1: #0c0c0c; 
    --color-2: #222222;
    --color-3: #01db0c; 
    --color-4: #31c73838; 
    --color-5: #ff6118;
    --color-6: #FFFFFF; 
    

    /* general */
    --max-width: 1300px;
    --side-margin: 30px;
    --side-margin-mobile: 6px;
    --button-margin: 30px;


}


