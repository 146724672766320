/* src/index.css */
@import url('https://fonts.googleapis.com/css2?family=Source+Code+Pro:wght@400;700&display=swap');

body, * {
  font-size: 1.1rem;
  font-family: 'Source Code Pro', monospace;

  color: var(--color-3);
  background-color: rgba(0, 0, 0, 0);
  text-shadow:
  0 0 5px rgba(0, 255, 0, 0.2),
  0 0 10px rgba(0, 255, 0, 0.1),
  0 0 20px rgba(0, 255, 0, 0.2),
  0 0 40px rgba(0, 255, 0, 0.2),
  0 0 80px rgba(0, 255, 0, 0.3);
  text-decoration: none;
}

p {
  font-size: 0.9rem;
}

body {
    background-color: var(--color-1);
}

button {
  margin-bottom: var(--button-margin);
  border-width: 0;
  text-align: left;
}



body, #root {
  height: 100vh;
  margin: 0px;
  display: flex;
}

#root {
  flex-grow: 1;
  justify-content: center;
}

.screen {
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100%;
  max-width: var(--max-width);
  height: 100%;
  background-image: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/86186/crt.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  pointer-events: none;
  z-index: 100;
}

.screen::after, .screen::before {
  content: '';
  position: fixed;
  pointer-events: none;
  z-index: 100;
  top: 0px;
  height: 100%;
  background-color: black;
}

.screen::after {
  left: 0px;
  width: calc(50vw - 650px);
}

.screen::before {
  right: 0px;
  width: calc(50vw - 650px);
}

.none {
  color: rgba(0, 0, 0, 0);
}