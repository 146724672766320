/* /src/component/main.css */
@import '../variables.css';


.main {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 5px;
  max-width: var(--max-width);
  background-color: rgba(0, 0, 0, 0);
  /* margin: auto; */
  /* min-height: 80vh; */
  flex-grow: 1;
  margin: 0px var(--side-margin);
}


.main-section {
  padding: 20px;
  border: 1px solid var(--color-3);
  background-color: rgba(0, 0, 0, 0);
  box-shadow:
  0 0 5px rgba(0, 255, 0, 0.02),
  0 0 10px rgba(0, 255, 0, 0.02),
  0 0 20px rgba(0, 255, 0, 0.02),
  0 0 40px rgba(0, 255, 0, 0.08);
  
}
  
@media (max-width: 800px) {
    .main {
        grid-template-columns: repeat(1, 1fr);
        margin: 0px var(--side-margin-mobile);
      }
  }

