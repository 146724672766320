.grid-gallery {
    position: relative;
}

.image-grid {
    display: grid;
    gap: 10px;
}

.grid-image {
    width: 100%;
    height: auto;
    cursor: pointer;
    transition: transform 0.3s ease; /* Smooth transition for the scaling effect */
    aspect-ratio: 1 / 1; /* Ensure the image container is always square */
    object-fit: cover; /* Ensure the image covers the entire container without stretching */
}

.grid-image:hover {
    transform: scale(1.1); /* Scale the image to 1.1 times its original size */
}

.black-box {
    width: 400px; /* Adjust this to match the card's max width */
    height: auto;
    aspect-ratio: 1 / 1; /* Maintain aspect ratio, change this if needed */
    background-color: var(--color-1);
    display: flex;
    justify-content: center;
    align-items: center;
}

.loading {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: var(--color-3);
    z-index: 1000; /* Make sure it's above other elements */
}

.card {
    max-width: 400px;
    max-height: 80vh;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 20px;
    border: 1px solid var(--color-3);
    background-color: rgba(0, 0, 0, 0.897);
    box-shadow:
    0 0 5px rgba(0, 255, 0, 0.02),
    0 0 10px rgba(0, 255, 0, 0.02),
    0 0 20px rgba(0, 255, 0, 0.02),
    0 0 40px rgba(0, 255, 0, 0.08);
    z-index: 500;
}

.card-image {
    max-width: 400px;
    width: 100%;
    height: auto;
    aspect-ratio: 1 / 1; /* Ensure the image container is always square */
    object-fit: cover; /* Ensure the image covers the entire container without stretching */
}

.card-text {
    margin-top: 10px;
    white-space: pre-line; /* Ensure that line breaks are maintained */
    overflow-y: auto; /* Enable vertical scrolling if content overflows */
    overflow-x: clip; /* Enable vertical scrolling if content overflows */
    max-height: calc(80vh - 400px);
}


.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 100;
    backdrop-filter: blur(5px); /* Adjust the blur radius as needed */
}

.close-button {
    position: absolute;
    top: 5px;
    right: 5px;
    background: var(--color-1);
    color: var(--color-3);
    border: none;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    cursor: pointer;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 600;
}

.close-button:hover {
    background-color: darkgreen;
}

@media (max-width: 800px) {
    .card {
        width: 80%; /* Adjust width as needed */
        max-width: 300px; /* Maximum width of the card */
        max-height: 80vh;
    }

    .card-image {
        max-width: 100%; /* Ensure the image width respects its container */
        height: auto;
        aspect-ratio: 1 / 1; /* Ensure the image container is always square */
        object-fit: cover; /* Ensure the image covers the entire container without stretching */
    }
    .card-text {
        font-size: 12px;
        max-height: calc(80vh - 310px);
    }
}
