/* /src/component/menu.css */
.menu {
  background-color: rgba(0, 0, 0, 0.9);
  border: 1px solid var(--color-3);
  box-shadow: 0 0 10px rgba(0, 255, 0, 0.2);
  padding: 10px;
  position: absolute;
  top: 50px; 
  right: 20px; 
  z-index: 100;
  width: 140px;
  display: flex;
  flex-direction: column;
  align-items: left;
}
  

.menu a{
  margin-bottom: var(--button-margin);
}

.line {
  border-bottom: 1px dashed var(--color-3);
  margin-bottom: 25px;
}