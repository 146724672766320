/* /src/component/main_content/main_content.css */
@import '../../variables.css';


.page {
    max-width: var(--max-width);
    background-color: rgba(0, 0, 0, 0);
}
  
.page-section{
    padding: 40px;
    margin-bottom: 5px;
    border: 1px solid var(--color-3);
    background-color: rgba(0, 0, 0, 0);
    box-shadow:
    0 0 5px rgba(0, 255, 0, 0.02),
    0 0 10px rgba(0, 255, 0, 0.02),
    0 0 20px rgba(0, 255, 0, 0.02),
    0 0 40px rgba(0, 255, 0, 0.08);
    margin: 0px 30px;
    
}

.page-section p{
    max-width: 800px;
}
  
@media (max-width: 800px) {
    .page-section {
        margin: 0px var(--side-margin-mobile);
      }
  }
    
@media (max-width: 450px) {
    .page-section {
        padding: 20px;
        
    }
}
  
.x {
    margin: 0px;
} 


