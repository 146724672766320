.foot {
    max-width: var(--max-width);
    border: 1px solid var(--color-3);
    background-color: rgba(0, 0, 0, 0);
    box-shadow:
    0 0 5px rgba(0, 255, 0, 0.02),
    0 0 10px rgba(0, 255, 0, 0.02),
    0 0 20px rgba(0, 255, 0, 0.02),
    0 0 40px rgba(0, 255, 0, 0.08);
    position: relative;
    margin: 5px var(--side-margin) 0 ;
    border-radius: 0px 0px 15px 15px; 
}

.icons {
    background-color: rgba(0, 0, 0, 0);
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px 20px;
}

.icons svg{
    margin: 10px 20px;
    cursor: pointer;
}

a:hover {
    background-color: var(--color-2)
}

@media (max-width: 800px) {
    .foot {
        margin: 5px var(--side-margin-mobile) 0;
      }
  }